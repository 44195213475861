// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-cidadania-italiana-jsx": () => import("./../../../src/pages/cidadania-italiana.jsx" /* webpackChunkName: "component---src-pages-cidadania-italiana-jsx" */),
  "component---src-pages-contato-jsx": () => import("./../../../src/pages/contato.jsx" /* webpackChunkName: "component---src-pages-contato-jsx" */),
  "component---src-pages-lista-jsx": () => import("./../../../src/pages/lista.jsx" /* webpackChunkName: "component---src-pages-lista-jsx" */),
  "component---src-pages-pesquisa-jsx": () => import("./../../../src/pages/pesquisa.jsx" /* webpackChunkName: "component---src-pages-pesquisa-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-latest-posts-jsx": () => import("./../../../src/templates/latest-posts.jsx" /* webpackChunkName: "component---src-templates-latest-posts-jsx" */),
  "component---src-templates-posts-by-category-jsx": () => import("./../../../src/templates/posts-by-category.jsx" /* webpackChunkName: "component---src-templates-posts-by-category-jsx" */),
  "component---src-templates-posts-by-place-trip-jsx": () => import("./../../../src/templates/posts-by-place-trip.jsx" /* webpackChunkName: "component---src-templates-posts-by-place-trip-jsx" */),
  "component---src-templates-posts-by-tag-jsx": () => import("./../../../src/templates/posts-by-tag.jsx" /* webpackChunkName: "component---src-templates-posts-by-tag-jsx" */)
}

