module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md",".markdown"],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embedder","options":{"customTransformers":[{"name":"Google-MyMaps"}],"services":{}}},{"resolve":"gatsby-remark-custom-blocks","options":{"blocks":{"info":{"classes":"info","title":"optional"},"warning":{"classes":"warning","title":"optional"},"neutral":{"classes":"neutral","title":"optional"},"sectionHead":{"classes":"section-head","title":"optional"},"fakeButton":{"classes":"fake-button","title":"optional"}}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":630,"showCaptions":true}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/adrianodc/uncaffeperdue.com","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-153079812-1","anonymize":true,"cookieName":"gatsby-gdpr-google-analytics"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Un caffè per due","short_name":"Un caffè per due","start_url":"/","background_color":"#ffffff","theme_color":"#53341B","display":"minimal-ui","icon":"content/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"24dbaa85329121d495860e4d7e81d99c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
